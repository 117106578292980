import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet";
import ImageZoom from 'react-medium-image-zoom'
import "@fortawesome/fontawesome-pro/css/all.css"


import Layout from '../../components/layout'
import Header from '../../components/header'
import ReturnToTop from '../../components/returnToTop';
import JumpLinks from '../../components/portfolioJumpLink'

import ImageSketch1 from '../../images/nexus/nexus-sketch1.jpg';
import ImageSketch2 from '../../images/nexus/nexus-sketch2.jpg';
import ImageSketch3 from '../../images/nexus/nexus-sketch3.jpg';
 
import ImageParties from '../../images/nexus/nexus-parties.png';
import ImagePersona1 from '../../images/nexus/nexus-persona-internal.jpg';
import ImagePersona2 from '../../images/nexus/nexus-persona-external.jpg';
import ImageTaskAnalysis1 from '../../images/nexus/nexus-taskanalysis1.jpg';
import ImageTaskAnalysis2 from '../../images/nexus/nexus-taskanalysis2.jpg';

import ImageDrafting from '../../images/nexus/nexus-drafting.jpg';
import ImageUISort from '../../images/nexus/nexus-ui-sort.jpg';
import ImageMockupDrafts from '../../images/nexus/nexus-mockup-drafts.png';


import ImageProcessOld from '../../images/nexus/nexus-process-current.jpg';
import ImageProcessNew from '../../images/nexus/nexus-process-future.jpg';
import ImageProcess from '../../images/nexus/nexus-process.png';

import ImageWireframe1 from '../../images/nexus/nexus-wireframe1.jpg';
import ImageWireframe2 from '../../images/nexus/nexus-wireframe2.jpg';
import ImageWireframe3 from '../../images/nexus/nexus-wireframe3.jpg';
import ImageWireframe4 from '../../images/nexus/nexus-wireframe4.jpg';
import ImageUserflow from '../../images/nexus/nexus-userflow-1.png';

import ImageApp from '../../images/nexus/nexus-final.jpg';
import ImageAppOverview from '../../images/nexus/nexus-final-overview.jpg';

import ImageShelf1 from '../../images/nexus/nexus-shelf-1.jpg';
import ImageShelf2 from '../../images/nexus/nexus-shelf-2.jpg';
import ImageShelf3 from '../../images/nexus/nexus-shelf-3.jpg';




const Portfolio1 = ({data}) => (
  <Layout>
      <main className="page--portfolio portfolio--globaltax">
      <Helmet>
          <title>{data.site.siteMetadata.nexusTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--hero" name="hero">
          <Header siteTitle="Rob DiMarzo" /> 
          <div className="slab__inner">
            <div className="pageblock--title">
              <h1>
                {data.site.siteMetadata.nexusTitle}
              </h1>
              <span>
                {data.site.siteMetadata.nexusDesc}              
              </span>
              <JumpLinks/>
            </div>
          </div>  
        </section>

        <section className="slab slab--notification">
          <div className="slab__inner">
            <p>
            Due to the sensitive and proprietary nature of this product, certain information has been withheld and modified. This product will be referred to as <strong>Nexus</strong> and the client as <strong>Kappa</strong>.
            </p>
          </div>
        </section>
        
        <section className="slab slab--intro" name="intro" >
          <div className="slab__overlay"></div>
          <div className="slab__inner">
            <div className="pageblock--title">
              <h2>Project Backstory</h2>
              <div className="title__line"></div>
            </div>
            <div className="pageblock">
              <div className="pageblock__content">
                <p>A global reach is essential to a diverse portfolio. To provide investors with opportunities in foreign markets, Asset Management companies form and manage funds that invest in international real estate, stocks, bonds, and commodities. </p>

                <p><b>But where profits are made, taxes must be paid.</b></p>
                                
                <p>For each country that investment activity occurs in, the Asset Manager has a legal obligation to file with that country's tax authority. Due to complex investment structures across several countries with different tax policies, legal guidelines, and means of communication, this process becomes difficult to coordinate, track, and deliver on time. <b>Any mistakes in this process can lead to huge financial penalties,</b> so Asset Managers rely on global tax specialists to manage their international filing obligations. </p>
                
                <p>We decided to create Nexus to provide a standardized technology platform for global teams to overcome the inefficiencies within this business function. </p>
              </div>
            </div>
          </div>
        </section>
      
        <section className="slab slab--problem" name="goals-challenges">
          <div className="slab__inner">

            <div className="pageblock--title">  
                <h2>Identifying the goals & challenges</h2>
                <div className="title__line"></div>
                <p>
                  I spoke with tax leaders and fund managers in several countries across a variety of asset markets to understand the needs and challenges of their global engagements.
                </p>
            </div>

            <div className="pageblock">
                <i className="fal fa-globe-americas"></i>
                <h3>
                  Global Connectivity
                </h3>
                <div className="pageblock__content">
                  <p>
                   International engagements require the collaboration of teams in several countries, but each team can have unique processes, culture, and technology in place that create connectivity and communication hurdles. 
                  </p>
                </div>
            </div>
            
            <div className="pageblock">
              <i className="fal fa-search-dollar"></i>
              <h3>
                Real-time Transparency
              </h3>
              <div className="pageblock__content">
                <p>
                   Without a centralized technology solution, data from each country remained confined to its own borders. This division made it impossible to receive real-time insights into the engagement status and effectively share information between countries.
                  </p>
              </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-exclamation-square"></i>
              <h3>
                Accurate Reporting
              </h3>
              <div className="pageblock__content">
                <p>
                  Valuable insights were not able to be captured and standardized due to the divisions - preventing tax teams and fund managers from synthesizing accurate and timely reports to assist and guide their operations. 
                </p>
              </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-ball-pile"></i>
              <h3>
                Finding meaning in the data
              </h3>
              <div className="pageblock__content">
                <p>
                  Although a wealth of data is captured, it is meaningless unless curated correctly.  In the current process, an administrator would regularly collect and analyze documents regarding the services being provided in each country, then provide a digest to update the client and tax leaders. 
                </p>
                <p>
                  Not only is this process manual and time consuming, but it resulted in uninsightful, outdated, and inaccurate data.
                </p>
              </div>  
            </div>
          </div>
        </section>

        <section className="slab slab--solution" name="my-role">
          <div className="slab__inner">
            <div className="pageblock--title">
                <h2>My role in the product</h2>
                <div className="title__line"></div>
                <div className="pageblock__content">
                  <p>
                    I was the <strong>Lead UX Researcher, UI Designer, and UX Engineer </strong> on this project, providing end-to-end UX services - from strategy and research, to design and development. I worked alongside 15 Users, 6 Full-stack Developers, 2 UX Contractors, 2 QA, 2 Business Analysts, and several internal and external stakeholders from across the globe. I was personally responsible for the following:
                  </p>
                </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-users-crown"></i>
              <h3>
                Design Leadership & User Advocacy
              </h3>
              <div className="pageblock__content">
                <p>
                  I established UX as a guiding voice amongst business stakeholders to advocate user-centered design principles throughout all stages of product design and development.
                </p>
                <p>
                  My leadership role extended into the development team as a subject matter expert, where I helped to contextualize business and technical requirements from the perspective of the end-user.
                </p>
              </div>
            </div>

            <figure className="pageblock--image"> 
              <ImageZoom
                  image={{
                    src: ImageParties,
                    alt: 'Diagram of the involved parties in this process',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageParties,
                    alt: 'Diagram of the involved parties in this process',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                The nature of global asset management is ambiguous to non-business users (like the development team), so I designed and validated this diagram to summarize and communicate the business structure and where The Firm fits in. 
              </figcaption>
            </figure>

            
            <div className="pageblock">
              <i className="fal fa-user-chart"></i>
              <h3>
                User Research
              </h3>
              <div className="pageblock__content">
                <p>
                  I spoke with several tax leaders in eight countries who oversee the taxes for some of the world’s largest funds. My goal was to understand the processes, needs, and challenges that were common and unique across countries and assets.</p>
                <p>
                    My findings were documented as personas, journey maps, and diagrams that were used throughout product development.
                </p>
              </div>
            </div>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImagePersona1,
                    alt: 'Persona summaries for internal users',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersona1,
                    alt: 'Persona summaries for internal users',
                    className: 'img--zoomed'
                  }}
                />

              <figcaption>
                A summarized digest of Nexus' internal users and their primary needs. 
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
                <ImageZoom
                  image={{
                    src: ImagePersona2,
                    alt: 'Persona summaries for external users',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersona2,
                    alt: 'Persona summaries for external users',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A summarized digest of Nexus' external users and their primary needs. 
              </figcaption>
            </figure>

            {/* <figure className="pageblock--image is--full"> 
                <ImageZoom
                  image={{
                    src: ImageTaskAnalysis2,
                    alt: 'Task analysis notes',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageTaskAnalysis2,
                    alt: 'Task analysis notes',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Based on interviews and surveys, internal and external user stories were consolidated and given weight by frequency of mention. 
              </figcaption>
            </figure> */}

            <div className="pageblock">
              <i className="fal fa-head-side-brain"></i>
              <h3>
                Design Thinking
              </h3>
              <div className="pageblock__content">
                <p>
                  I collected and analyzed business documents, legislation, technical processes, company profiles, and competitor tools to become familiarized with the domain. This knowledge allowed me to carry conversations with users and stakeholders, stimulate creative problem solving, discover new solutions, map the user journey, and design the ideal user experience.
                </p>
              </div>
            </div>
            
            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageProcess,
                    alt: 'Diagram of the old and future business processes',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageProcess,
                    alt: 'Diagram of the old and future business processes',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
               A diagram illustrating the gaps and opportunites between the old vs new business process. Without Nexus, the process was a time-consuming global operation that was prone to inaccuracies - but with Nexus, the automated sync keeps data fresh and standardized without manual effort. 
              </figcaption>
            </figure>


            <div className="pageblock">
              <i className="fal fa-object-group"></i>
              <h3>
                Wireframes & Prototyping
              </h3>
              <div className="pageblock__content">
                <p>
                  One year prior - I created an initial prototype based on limited user research to be presented at investment banking events to gauge industry interest. Many of the largest asset management firms in the world grasped on to the product's vision, helping to secure the funding needed for development. 
                </p>
                <p>
                  The next year - I was able to conduct further user research and create new designs that addressed the gaps from the initial round. Working alongside two other UX designers, we designed several iterations of mockups and prototypes to present to users, stakeholders, and clients for validation of business requirements. In order to get accurate feedback, we had to create several variations of the prototypes that were customized with each client's data and taxonomy. <b>The biggest challenge during this process was trying to design a UI that was able to satisfy the diverse needs of asset managers in different regions.</b>
                </p>
                <p>
                  After several rounds of exploring various layouts, data visualizations, and content strategies, we were able to design a product that is flexible to the diverse needs of global asset management. Some final design elements include a globally accessible shelf  that connects and relates content throughout the app, pervasive filtering to slice-and-dice data as the user sees fit, and an intuitive information scent that guides the user towards high-priority content. 
                </p>
                <p><u>Tools used for design:</u></p> 
                  <ul className="content__skills">
                    <li>Whiteboards, Pen & Paper</li>
                    <li>Sketch</li>
                    <li>Invision</li>
                    <li>HTML, CSS, JavaScript</li>
                  </ul>
              </div>
            </div>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageDrafting,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageDrafting,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A collection of my sketches and whiteboarding sessions throughout the discovery and design process. 
              </figcaption>
            </figure>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageUISort,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageUISort,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A few outcomes of a closed UI consensus exercise (similar to closed card sorting for UI layout instead of IA) for Nexus' overview page. I created post-it notes representing content from our task analysis data, then prompted 6 users to organize the content according to their expectations.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageMockupDrafts,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockupDrafts,
                    alt: 'Consensus building exercises for Nexus homepage',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A variety of mockups with different visual styles, information architectures, layouts, and data visualizations were initially produced to help gauge industry interest and facilitate meaningful feedback from stakeholders and users. 
              </figcaption>
            </figure>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageUserflow,
                    alt: 'Userflow for new Nexus features',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageUserflow,
                    alt: 'Userflow for new Nexus features',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                I created user flows when introducing new feature throughout the development process to inform functional specs, visualize use cases, and validate business requirements.
              </figcaption>
            </figure>



          



          <div className="pageblock">
                <i className="fal fa-code"></i>
                <h3>
                  UX Engineering                
                </h3>
                <div className="pageblock__content">
                  <p>
                    I was embedded within the development team to ensure the quality of my high-fidelity mockups made it into the product. I authored Sass stylesheets, designed interactive animations, developed responsive layouts, and established accessible components. I also wrote UI/UX requirements and performed code reviews to maintain high-quality and consistent front-end code across the team.
                  </p>
                  <p><u>Development skills used:</u></p> 
                  <ul className="content__skills">
                    <li>HTML</li>
                    <li>CSS, SCSS</li>
                    <li>SVG</li>
                    <li>WAI-ARIA</li>
                    <li>JavaScript</li>
                    <li>Angular</li>
                  </ul>
                </div>
            </div>


            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageAppOverview,
                    alt: 'Redacted image of Nexus',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageAppOverview,
                    alt: 'Redacted image of Nexus',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  Nexus' Overview page provides users with relevant insights into the current engagement status, what's upcoming, what's happening right now, and hooks to dive into further levels of detail. 
              </figcaption>
            </figure>


            <figure className="pageblock--image is--third"> 
              <ImageZoom
                  image={{
                    src: ImageShelf1,
                    alt: 'Entity Information Shelf',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageShelf1,
                    alt: 'Entity Information Shelf',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                 The Entity > Information shelf displays an overview of the selected entity, like the jurisdictions it operates in, the country its established in, and its attributes.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--third"> 
              <ImageZoom
                  image={{
                    src: ImageShelf2,
                    alt: 'Entity Milestone Shelf',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageShelf2,
                    alt: 'Entity Milestone Shelf',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                 The Entity > Milestones shelf displays all the services we have provided for the selected entity, along with a heatmap visualizing our delivery performance over the past year. 
              </figcaption>
            </figure>

            <figure className="pageblock--image is--third"> 
              <ImageZoom
                  image={{
                    src: ImageShelf3,
                    alt: 'Milestone Tasks Shelf',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageShelf3,
                    alt: 'Milestone Tasks Shelf',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                 The Milestone > Tasks shelf displays the workflow assignments and due dates for the selected deliverable, and allows users to complete tasks and attach associated documents. 
              </figcaption>
            </figure>


            






          </div>
        </section>  
    </main>
      <ReturnToTop/>
  </Layout>
)

export const query = graphql`
  query NexusQuery {
    site {
      siteMetadata {
        title
        nexusTitle
        nexusDesc
      }
    }
  }
`
export default Portfolio1

